// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortfolioItems_PortfolioItems__mj56Y {
}

.PortfolioItems_PortfolioTitle__do8Ki {
  font-weight: 500;
  font-size: 20px;
}

.PortfolioItems_PortfolioDescription__DXhu1 {
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/Portfolio/PortfolioHelpers/PortfolioItems/PortfolioItems.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".PortfolioItems {\n}\n\n.PortfolioTitle {\n  font-weight: 500;\n  font-size: 20px;\n}\n\n.PortfolioDescription {\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PortfolioItems": `PortfolioItems_PortfolioItems__mj56Y`,
	"PortfolioTitle": `PortfolioItems_PortfolioTitle__do8Ki`,
	"PortfolioDescription": `PortfolioItems_PortfolioDescription__DXhu1`
};
export default ___CSS_LOADER_EXPORT___;
