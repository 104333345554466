// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Name_Name__v2TPT {
  font-size: larger;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/ResumeHelpers/Name/Name.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".Name {\n  font-size: larger;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Name": `Name_Name__v2TPT`
};
export default ___CSS_LOADER_EXPORT___;
