// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --textColor: black;
  --link: #40a4c8;
  --backgroundColor: var(--bs-body-bg);
  --hoverColor: black;
  --foregroundColor: black;
  --svgFill: #ffffff;
  --svgStroke: #212529;
  --collapsedContentOutline: black;
  --darkModeSwitch: #41464b;
  --sectionShadowColor: #242424;
  --navBorderColor: #e0e0e0;
}

.body-dark {
  --textColor: #eeeeee;
  --link: #ffd369;
  --backgroundColor: #222831;
  --hoverColor: #8f98a5;
  --foregroundColor: #ffd369;
  --svgFill: #8f98a5;
  --svgStroke: #222831;
  --collapsedContentOutline: #8f98a5;
  --sectionShadowColor: #08090c;
  --navBorderColor: #8f98a5;
}
`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,oCAAoC;EACpC,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;EAClB,oBAAoB;EACpB,gCAAgC;EAChC,yBAAyB;EACzB,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,0BAA0B;EAC1B,qBAAqB;EACrB,0BAA0B;EAC1B,kBAAkB;EAClB,oBAAoB;EACpB,kCAAkC;EAClC,6BAA6B;EAC7B,yBAAyB;AAC3B","sourcesContent":[":root {\n  --textColor: black;\n  --link: #40a4c8;\n  --backgroundColor: var(--bs-body-bg);\n  --hoverColor: black;\n  --foregroundColor: black;\n  --svgFill: #ffffff;\n  --svgStroke: #212529;\n  --collapsedContentOutline: black;\n  --darkModeSwitch: #41464b;\n  --sectionShadowColor: #242424;\n  --navBorderColor: #e0e0e0;\n}\n\n.body-dark {\n  --textColor: #eeeeee;\n  --link: #ffd369;\n  --backgroundColor: #222831;\n  --hoverColor: #8f98a5;\n  --foregroundColor: #ffd369;\n  --svgFill: #8f98a5;\n  --svgStroke: #222831;\n  --collapsedContentOutline: #8f98a5;\n  --sectionShadowColor: #08090c;\n  --navBorderColor: #8f98a5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
