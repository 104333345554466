// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Education_SchoolName__GVllP {
  padding-top: 8px;
  font-weight: 600;
}

.Education_Degree__9L7GE {
  padding-top: 3px;
  margin-left: 8px;
}

.Education_EducationBody__r6GLJ {
  max-height: 15em;
}

@media (min-width: 500px) {
  .Education_EducationBody__r6GLJ {
    max-height: 10em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/Education/Education.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".SchoolName {\n  padding-top: 8px;\n  font-weight: 600;\n}\n\n.Degree {\n  padding-top: 3px;\n  margin-left: 8px;\n}\n\n.EducationBody {\n  max-height: 15em;\n}\n\n@media (min-width: 500px) {\n  .EducationBody {\n    max-height: 10em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SchoolName": `Education_SchoolName__GVllP`,
	"Degree": `Education_Degree__9L7GE`,
	"EducationBody": `Education_EducationBody__r6GLJ`
};
export default ___CSS_LOADER_EXPORT___;
