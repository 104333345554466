// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Heading_Heading__EKv88 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.Heading_Description__BNfjr {
  align-self: flex-start;
}

.Heading_ImageContainer__naLyP {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.Heading_MyImage__JAqkC {
  width: 100%;
  height: auto;
  align-self: flex-end;
  border-radius: 15%;
  box-shadow: 0 2px 4px var(--sectionShadowColor);
}

@media screen and (min-width: 550px) {
  .Heading_Heading__EKv88 {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }

  .Heading_MyImage__JAqkC {
    height: 300px;
    width: auto;
  }

  .Heading_ImageContainer__naLyP {
    padding-bottom: 0px !important;
  }

  .Heading_Description__BNfjr {
    align-self: flex-end;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/Heading/Heading.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE;IACE,8BAA8B;IAC9B,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,WAAW;EACb;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".Heading {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column-reverse;\n}\n\n.Description {\n  align-self: flex-start;\n}\n\n.ImageContainer {\n  margin-left: auto;\n  margin-right: auto;\n  padding-bottom: 24px;\n}\n\n.MyImage {\n  width: 100%;\n  height: auto;\n  align-self: flex-end;\n  border-radius: 15%;\n  box-shadow: 0 2px 4px var(--sectionShadowColor);\n}\n\n@media screen and (min-width: 550px) {\n  .Heading {\n    flex-direction: row !important;\n    flex-wrap: nowrap !important;\n  }\n\n  .MyImage {\n    height: 300px;\n    width: auto;\n  }\n\n  .ImageContainer {\n    padding-bottom: 0px !important;\n  }\n\n  .Description {\n    align-self: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Heading": `Heading_Heading__EKv88`,
	"Description": `Heading_Description__BNfjr`,
	"ImageContainer": `Heading_ImageContainer__naLyP`,
	"MyImage": `Heading_MyImage__JAqkC`
};
export default ___CSS_LOADER_EXPORT___;
