// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-down-arrow {
  right: 0;
  height: 16px;
  width: 16px;
  stroke: var(--textColor);
  fill: var(--textColor);
}
`, "",{"version":3,"sources":["webpack://./src/common/DownArrow/DownArrow.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,sBAAsB;AACxB","sourcesContent":[".container-down-arrow {\n  right: 0;\n  height: 16px;\n  width: 16px;\n  stroke: var(--textColor);\n  fill: var(--textColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
