// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  color: black;
  text-decoration: none;
  width: 100%;
}

a:hover,
a:active,
a.HomePage_active__eZoGS {
  color: #40a4c8;
}

.HomePage_Home__c2cbA {
  margin-left: 16px;
  margin-right: 16px;
}

@media screen and (min-width: 832px) {
  .HomePage_Home__c2cbA {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/Home/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,WAAW;AACb;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":["a {\n  color: black;\n  text-decoration: none;\n  width: 100%;\n}\n\na:hover,\na:active,\na.active {\n  color: #40a4c8;\n}\n\n.Home {\n  margin-left: 16px;\n  margin-right: 16px;\n}\n\n@media screen and (min-width: 832px) {\n  .Home {\n    width: 800px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `HomePage_active__eZoGS`,
	"Home": `HomePage_Home__c2cbA`
};
export default ___CSS_LOADER_EXPORT___;
