// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionContainer_Section__W6qVV {
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.SectionContainer_SectionContainer__hplDl {
  border: 1px solid var(--textColor);
  box-shadow: 0 2px 3px var(--sectionShadowColor);
  border-radius: 24px;
  margin-top: 24px;
  margin-left: 4px;
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/utils/SectionContainer/SectionContainer.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;EAClC,+CAA+C;EAC/C,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".Section {\n  padding-bottom: 12px;\n  padding-left: 24px;\n  padding-right: 24px;\n}\n\n.SectionContainer {\n  border: 1px solid var(--textColor);\n  box-shadow: 0 2px 3px var(--sectionShadowColor);\n  border-radius: 24px;\n  margin-top: 24px;\n  margin-left: 4px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Section": `SectionContainer_Section__W6qVV`,
	"SectionContainer": `SectionContainer_SectionContainer__hplDl`
};
export default ___CSS_LOADER_EXPORT___;
