import React from 'react';

import classes from './Summary.module.css';

import SectionTitle from '../ResumeHelpers/SectionTitle/SectionTitle';
import SectionBody from '../ResumeHelpers/SectionBody/SectionBody';

const Summary: React.FC<{}> = () => {
  return (
    <div className={classes.Summary}>
      <SectionTitle>Summary</SectionTitle>
      <SectionBody>
        Experienced software developer with 8.5 years in the gaming and sports betting industries. Proficient in C++ and
        JavaScript, I deliver robust applications and user-friendly software solutions. Skilled in Cordova, Capacitor,
        and React Native for mobile development, I thrive in cross-functional teams and stay current with the latest
        industry trends.
      </SectionBody>
    </div>
  );
};

export default Summary;
