// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationItem_NavigationItem__5Ih1h {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding-right: 20%;
}

.NavigationItem_NavigationItem__5Ih1h a {
  color: var(--hoverColor) !important;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid var(--hoverColor);
}

.NavigationItem_NavigationItem__5Ih1h a:hover,
.NavigationItem_NavigationItem__5Ih1h a:active,
.NavigationItem_NavigationItem__5Ih1h a.NavigationItem_active__uQZW1 {
  color: var(--link) !important;
}

@media (min-width: 500px) {
  .NavigationItem_NavigationItem__5Ih1h {
    margin: 0;
    display: flex;
    height: 55px;
    width: auto;
    align-items: center;
    padding-right: 0;
  }

  .NavigationItem_NavigationItem__5Ih1h a {
    color: var(--textColor) !important;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem_NavigationItem__5Ih1h a:hover,
  .NavigationItem_NavigationItem__5Ih1h a:active,
  .NavigationItem_NavigationItem__5Ih1h a.NavigationItem_active__uQZW1 {
    border-bottom: 4px solid var(--link);
    color: var(--textColor) !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavigationItems/NavigationItem/NavigationItem.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;EACtB,cAAc;EACd,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,qBAAqB;EACrB,WAAW;EACX,sBAAsB;EACtB,cAAc;EACd,0CAA0C;AAC5C;;AAEA;;;EAGE,6BAA6B;AAC/B;;AAEA;EACE;IACE,SAAS;IACT,aAAa;IACb,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;EACtC;;EAEA;;;IAGE,oCAAoC;IACpC,kCAAkC;EACpC;AACF","sourcesContent":[".NavigationItem {\n  margin: 10px 0;\n  box-sizing: border-box;\n  display: block;\n  width: 100%;\n  padding-right: 20%;\n}\n\n.NavigationItem a {\n  color: var(--hoverColor) !important;\n  text-decoration: none;\n  width: 100%;\n  box-sizing: border-box;\n  display: block;\n  border-bottom: 1px solid var(--hoverColor);\n}\n\n.NavigationItem a:hover,\n.NavigationItem a:active,\n.NavigationItem a.active {\n  color: var(--link) !important;\n}\n\n@media (min-width: 500px) {\n  .NavigationItem {\n    margin: 0;\n    display: flex;\n    height: 55px;\n    width: auto;\n    align-items: center;\n    padding-right: 0;\n  }\n\n  .NavigationItem a {\n    color: var(--textColor) !important;\n    height: 100%;\n    padding: 16px 10px;\n    border-bottom: 4px solid transparent;\n  }\n\n  .NavigationItem a:hover,\n  .NavigationItem a:active,\n  .NavigationItem a.active {\n    border-bottom: 4px solid var(--link);\n    color: var(--textColor) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItem": `NavigationItem_NavigationItem__5Ih1h`,
	"active": `NavigationItem_active__uQZW1`
};
export default ___CSS_LOADER_EXPORT___;
