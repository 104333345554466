// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toolbar_Toolbar__wyFyE {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--backgroundColor);
  border-bottom: 1px solid var(--navBorderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar_Toolbar__wyFyE nav {
  height: 100%;
}

.Toolbar_Logo__Oyl1U {
  height: 80%;
}

.Toolbar_DarkModeSwitchContainer__XJXHm {
  flex: 1 1;
  display: flex;
}

@media (max-width: 499px) {
  .Toolbar_DesktopOnly__QdikG {
    display: none;
  }
  .Toolbar_DarkModeSwitchContainer__XJXHm {
    justify-content: flex-end;
  }
}

@media (min-width: 832px) {
  .Toolbar_Toolbar__wyFyE {
    width: 800px;
    left: 50%;
    transform: translateX(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Toolbar/Toolbar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,wCAAwC;EACxC,8CAA8C;EAC9C,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAO;EACP,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;IACT,2BAA2B;EAC7B;AACF","sourcesContent":[".Toolbar {\n  height: 56px;\n  width: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: var(--backgroundColor);\n  border-bottom: 1px solid var(--navBorderColor);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n  box-sizing: border-box;\n  z-index: 90;\n}\n\n.Toolbar nav {\n  height: 100%;\n}\n\n.Logo {\n  height: 80%;\n}\n\n.DarkModeSwitchContainer {\n  flex: 1;\n  display: flex;\n}\n\n@media (max-width: 499px) {\n  .DesktopOnly {\n    display: none;\n  }\n  .DarkModeSwitchContainer {\n    justify-content: flex-end;\n  }\n}\n\n@media (min-width: 832px) {\n  .Toolbar {\n    width: 800px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toolbar": `Toolbar_Toolbar__wyFyE`,
	"Logo": `Toolbar_Logo__Oyl1U`,
	"DarkModeSwitchContainer": `Toolbar_DarkModeSwitchContainer__XJXHm`,
	"DesktopOnly": `Toolbar_DesktopOnly__QdikG`
};
export default ___CSS_LOADER_EXPORT___;
