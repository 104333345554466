// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionBody_SectionBody__bJECp {
  padding-top: 8px;
  margin-left: 8px;

  transition: max-height 0.5s linear 0s;
  overflow: hidden;
}

.SectionBody_open__7FSMN {
  /* This max-height needs to be close to the height of the highest element
  the transition will begin and not be visible until the height of the element
  has been reached

  Ex: a max-height of 100em will take some time to transition to a 40em div */
  max-height: 45em;
}

@media (min-width: 500px) {
  .SectionBody_open__7FSMN {
    max-height: 45em;
  }
}

.SectionBody_closed__b3Dwt {
  max-height: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/ResumeHelpers/SectionBody/SectionBody.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;;EAEhB,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE;;;;6EAI2E;EAC3E,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".SectionBody {\n  padding-top: 8px;\n  margin-left: 8px;\n\n  transition: max-height 0.5s linear 0s;\n  overflow: hidden;\n}\n\n.open {\n  /* This max-height needs to be close to the height of the highest element\n  the transition will begin and not be visible until the height of the element\n  has been reached\n\n  Ex: a max-height of 100em will take some time to transition to a 40em div */\n  max-height: 45em;\n}\n\n@media (min-width: 500px) {\n  .open {\n    max-height: 45em;\n  }\n}\n\n.closed {\n  max-height: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SectionBody": `SectionBody_SectionBody__bJECp`,
	"open": `SectionBody_open__7FSMN`,
	"closed": `SectionBody_closed__b3Dwt`
};
export default ___CSS_LOADER_EXPORT___;
