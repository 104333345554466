// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideDrawer_SideDrawer__yVMeB {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: var(--backgroundColor);
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer_SideDrawer__yVMeB {
    display: none;
  }
}

.SideDrawer_Open__zALzX {
  transform: translateX(0);
}

.SideDrawer_Close__2S9cu {
  transform: translateX(-100%);
}

.SideDrawer_Logo__D7Vq7 {
  height: 11%;
  margin-bottom: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/SideDrawer/SideDrawer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,OAAO;EACP,MAAM;EACN,YAAY;EACZ,wCAAwC;EACxC,kBAAkB;EAClB,sBAAsB;EACtB,mCAAmC;AACrC;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".SideDrawer {\n  position: fixed;\n  width: 280px;\n  max-width: 70%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  z-index: 200;\n  background-color: var(--backgroundColor);\n  padding: 32px 16px;\n  box-sizing: border-box;\n  transition: transform 0.3s ease-out;\n}\n\n@media (min-width: 500px) {\n  .SideDrawer {\n    display: none;\n  }\n}\n\n.Open {\n  transform: translateX(0);\n}\n\n.Close {\n  transform: translateX(-100%);\n}\n\n.Logo {\n  height: 11%;\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SideDrawer": `SideDrawer_SideDrawer__yVMeB`,
	"Open": `SideDrawer_Open__zALzX`,
	"Close": `SideDrawer_Close__2S9cu`,
	"Logo": `SideDrawer_Logo__D7Vq7`
};
export default ___CSS_LOADER_EXPORT___;
