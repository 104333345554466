// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Portfolio_Portfolio__C3KLB {
  padding-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  color: var(--textColor);
  padding-bottom: 50px;
}

@media screen and (min-width: 832px) {
  .Portfolio_Portfolio__C3KLB {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  color: var(--link) !important;
  text-decoration: none;
}

a:hover,
a:active,
a.Portfolio_active__nispD {
  color: var(--hoverColor) !important;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Portfolio/Portfolio.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;;;EAGE,mCAAmC;EACnC,qBAAqB;AACvB","sourcesContent":[".Portfolio {\n  padding-top: 16px;\n  margin-left: 16px;\n  margin-right: 16px;\n  color: var(--textColor);\n  padding-bottom: 50px;\n}\n\n@media screen and (min-width: 832px) {\n  .Portfolio {\n    width: 800px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\na {\n  color: var(--link) !important;\n  text-decoration: none;\n}\n\na:hover,\na:active,\na.active {\n  color: var(--hoverColor) !important;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Portfolio": `Portfolio_Portfolio__C3KLB`,
	"active": `Portfolio_active__nispD`
};
export default ___CSS_LOADER_EXPORT___;
