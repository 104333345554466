// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfessionalHistory_Profession__L\\+jp\\+ {
  margin-bottom: 16px;
}

.ProfessionalHistory_CompanyTitle__2kyUu {
  padding-top: 8px;
  font-weight: 600;
}

.ProfessionalHistory_Role__NZ4nP {
  font-weight: 500;
}

.ProfessionalHistory_JobDescription__Cgplz {
  padding-top: 3px;
  margin-left: 8px;
}

.ProfessionalHistory_GamesList__GwIuN {
  margin-top: 0px;
  list-style-type: square;
}

.ProfessionalHistory_professionalHistoryBody__ZHPqP {
  max-height: 65em;
}

@media (min-width: 500px) {
  .ProfessionalHistory_professionalHistoryBody__ZHPqP {
    max-height: 45em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/ProfessionalHistory/ProfessionalHistory.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".Profession {\n  margin-bottom: 16px;\n}\n\n.CompanyTitle {\n  padding-top: 8px;\n  font-weight: 600;\n}\n\n.Role {\n  font-weight: 500;\n}\n\n.JobDescription {\n  padding-top: 3px;\n  margin-left: 8px;\n}\n\n.GamesList {\n  margin-top: 0px;\n  list-style-type: square;\n}\n\n.professionalHistoryBody {\n  max-height: 65em;\n}\n\n@media (min-width: 500px) {\n  .professionalHistoryBody {\n    max-height: 45em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Profession": `ProfessionalHistory_Profession__L+jp+`,
	"CompanyTitle": `ProfessionalHistory_CompanyTitle__2kyUu`,
	"Role": `ProfessionalHistory_Role__NZ4nP`,
	"JobDescription": `ProfessionalHistory_JobDescription__Cgplz`,
	"GamesList": `ProfessionalHistory_GamesList__GwIuN`,
	"professionalHistoryBody": `ProfessionalHistory_professionalHistoryBody__ZHPqP`
};
export default ___CSS_LOADER_EXPORT___;
