// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtherAccomplishments_OtherAccomplishments__F\\+OqO {
  padding-bottom: 50px;
}

.OtherAccomplishments_Accomplishment__rY2PL {
  margin-left: 16px;
  list-style-type: square;
}

.OtherAccomplishments_VideoCourses__OZJJu {
  margin-top: 0px;
  columns: 1;
  list-style-type: square;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/OtherAccomplishments/OtherAccomplishments.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,uBAAuB;AACzB","sourcesContent":[".OtherAccomplishments {\n  padding-bottom: 50px;\n}\n\n.Accomplishment {\n  margin-left: 16px;\n  list-style-type: square;\n}\n\n.VideoCourses {\n  margin-top: 0px;\n  columns: 1;\n  list-style-type: square;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OtherAccomplishments": `OtherAccomplishments_OtherAccomplishments__F+OqO`,
	"Accomplishment": `OtherAccomplishments_Accomplishment__rY2PL`,
	"VideoCourses": `OtherAccomplishments_VideoCourses__OZJJu`
};
export default ___CSS_LOADER_EXPORT___;
