// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionTitle_SectionTitle__uB-Br {
  font-weight: bold;
  padding-top: 12px;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/ResumeHelpers/SectionTitle/SectionTitle.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".SectionTitle {\n  font-weight: bold;\n  padding-top: 12px;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SectionTitle": `SectionTitle_SectionTitle__uB-Br`
};
export default ___CSS_LOADER_EXPORT___;
