// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsefulProficiencies_list__SLA8g {
  margin-top: 0px;
  list-style: square inside;
  padding-bottom: 16px;
}

.UsefulProficiencies_longList__ptcBQ {
  display: flex;
  flex-wrap: wrap;
}

.UsefulProficiencies_ListDescription__S8Amr {
  font-weight: 500;
}

.UsefulProficiencies_proficiency__KZjK- {
  flex: 0 0 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/ResumeComponents/UsefulProficiencies/UsefulProficiencies.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".list {\n  margin-top: 0px;\n  list-style: square inside;\n  padding-bottom: 16px;\n}\n\n.longList {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.ListDescription {\n  font-weight: 500;\n}\n\n.proficiency {\n  flex: 0 0 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `UsefulProficiencies_list__SLA8g`,
	"longList": `UsefulProficiencies_longList__ptcBQ`,
	"ListDescription": `UsefulProficiencies_ListDescription__S8Amr`,
	"proficiency": `UsefulProficiencies_proficiency__KZjK-`
};
export default ___CSS_LOADER_EXPORT___;
