// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2341464b%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DarkModeSwitch_DarkModeSwitch__rZHgU {
  border-color: var(--darkModeSwitch);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
}
.DarkModeSwitch_DarkModeContainer__2x2Na {
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 90;
}

.DarkModeSwitch_DarkModeSwitch__rZHgU:focus {
  border-color: var(--darkModeSwitch) !important;
  box-shadow: 0 0 0 0.05rem var(--darkModeSwitch) !important;
}

.DarkModeSwitch_DarkModeSwitch__rZHgU:checked {
  background-color: var(--darkModeSwitch) !important;
  border-color: var(--darkModeSwitch) !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
}

.DarkModeSwitch_DarkModeLabel__xW28C {
  color: var(--textColor);
  height: 32;
  width: 32;
}

@media (max-width: 499px) {
  .DarkModeSwitch_DesktopOnly__Vxx\\+h {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DarkModeSwitch/DarkModeSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,oEAAqK;AACvK;AACA;EACE,MAAM;EACN,OAAO;EACP,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,8CAA8C;EAC9C,0DAA0D;AAC5D;;AAEA;EACE,kDAAkD;EAClD,8CAA8C;EAC9C,oEAAkK;AACpK;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,SAAS;AACX;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".DarkModeSwitch {\n  border-color: var(--darkModeSwitch);\n  background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2341464b'/%3e%3c/svg%3e\") !important;\n}\n.DarkModeContainer {\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  z-index: 90;\n}\n\n.DarkModeSwitch:focus {\n  border-color: var(--darkModeSwitch) !important;\n  box-shadow: 0 0 0 0.05rem var(--darkModeSwitch) !important;\n}\n\n.DarkModeSwitch:checked {\n  background-color: var(--darkModeSwitch) !important;\n  border-color: var(--darkModeSwitch) !important;\n  background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e\") !important;\n}\n\n.DarkModeLabel {\n  color: var(--textColor);\n  height: 32;\n  width: 32;\n}\n\n@media (max-width: 499px) {\n  .DesktopOnly {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DarkModeSwitch": `DarkModeSwitch_DarkModeSwitch__rZHgU`,
	"DarkModeContainer": `DarkModeSwitch_DarkModeContainer__2x2Na`,
	"DarkModeLabel": `DarkModeSwitch_DarkModeLabel__xW28C`,
	"DesktopOnly": `DarkModeSwitch_DesktopOnly__Vxx+h`
};
export default ___CSS_LOADER_EXPORT___;
