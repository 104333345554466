// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationItems_NavigationItems__eY4A1 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

@media (min-width: 500px) {
  .NavigationItems_NavigationItems__eY4A1 {
    flex-flow: row;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavigationItems/NavigationItems.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".NavigationItems {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  height: 100%;\n}\n\n@media (min-width: 500px) {\n  .NavigationItems {\n    flex-flow: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItems": `NavigationItems_NavigationItems__eY4A1`
};
export default ___CSS_LOADER_EXPORT___;
