// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MFGLP_MFGLP__GOKfh {
  padding-top: 16px;
  height: 100%;
  margin-left: 16px;
  color: var(--textColor);
}

@media screen and (min-width: 832px) {
  .MFGLP_MFGLP__GOKfh {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.MFGLP_MFGLP__GOKfh a {
  text-decoration: none;
  color: var(--link) !important;
}

.MFGLP_MFGLP__GOKfh a:hover,
a:active,
a.MFGLP_active__r\\+3sO {
  color: var(--hoverColor) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MFGLandingPage/MFGLP.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;;;EAGE,mCAAmC;AACrC","sourcesContent":[".MFGLP {\n  padding-top: 16px;\n  height: 100%;\n  margin-left: 16px;\n  color: var(--textColor);\n}\n\n@media screen and (min-width: 832px) {\n  .MFGLP {\n    width: 800px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n.MFGLP a {\n  text-decoration: none;\n  color: var(--link) !important;\n}\n\n.MFGLP a:hover,\na:active,\na.active {\n  color: var(--hoverColor) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MFGLP": `MFGLP_MFGLP__GOKfh`,
	"active": `MFGLP_active__r+3sO`
};
export default ___CSS_LOADER_EXPORT___;
