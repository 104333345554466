// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Resume_Resume__f1-TS {
  padding-top: 16px;

  margin-left: 16px;
  margin-right: 16px;
  color: var(--textColor);
}

@media screen and (min-width: 832px) {
  .Resume_Resume__f1-TS {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Resume_Resume__f1-TS a {
  color: var(--link) !important;
  text-decoration: none;
}

.Resume_Resume__f1-TS a:hover,
a:active,
a.Resume_active__cn0iG {
  color: var(--hoverColor) !important;
}

.Resume_Resume__f1-TS b {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resume/Resume.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;;EAEjB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;;;EAGE,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".Resume {\n  padding-top: 16px;\n\n  margin-left: 16px;\n  margin-right: 16px;\n  color: var(--textColor);\n}\n\n@media screen and (min-width: 832px) {\n  .Resume {\n    width: 800px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n.Resume a {\n  color: var(--link) !important;\n  text-decoration: none;\n}\n\n.Resume a:hover,\na:active,\na.active {\n  color: var(--hoverColor) !important;\n}\n\n.Resume b {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Resume": `Resume_Resume__f1-TS`,
	"active": `Resume_active__cn0iG`
};
export default ___CSS_LOADER_EXPORT___;
