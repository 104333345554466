// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop_Backdrop__mRasm {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 500px) {
  .Backdrop_Backdrop__mRasm {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,OAAO;EACP,MAAM;EACN,oCAAoC;AACtC;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".Backdrop {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 100;\n  left: 0;\n  top: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n@media (min-width: 500px) {\n  .Backdrop {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": `Backdrop_Backdrop__mRasm`
};
export default ___CSS_LOADER_EXPORT___;
