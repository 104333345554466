// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_Home__PduFw {
  padding-top: 16px;
  height: 100%;
  margin-left: 16px;
  color: var(--textColor);
}

@media screen and (min-width: 832px) {
  .Home_Home__PduFw {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Home_Welcome__0ZNVU {
  font-weight: 600;
  font-size: 24px;
}

.Home_Home__PduFw a {
  text-decoration: none;
  color: var(--link) !important;
}

.Home_Home__PduFw a:hover,
a:active,
a.Home_active__vw8xW {
  color: var(--hoverColor) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;;;EAGE,mCAAmC;AACrC","sourcesContent":[".Home {\n  padding-top: 16px;\n  height: 100%;\n  margin-left: 16px;\n  color: var(--textColor);\n}\n\n@media screen and (min-width: 832px) {\n  .Home {\n    width: 800px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n.Welcome {\n  font-weight: 600;\n  font-size: 24px;\n}\n\n.Home a {\n  text-decoration: none;\n  color: var(--link) !important;\n}\n\n.Home a:hover,\na:active,\na.active {\n  color: var(--hoverColor) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Home": `Home_Home__PduFw`,
	"Welcome": `Home_Welcome__0ZNVU`,
	"active": `Home_active__vw8xW`
};
export default ___CSS_LOADER_EXPORT___;
