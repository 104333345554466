// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawerToggle_DrawerToggle__tnd\\+P {
  width: 45px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle_DrawerToggle__tnd\\+P div {
  width: 90%;
  height: 3px;
  background-color: var(--foregroundColor);
}

@media (min-width: 500px) {
  .DrawerToggle_DrawerToggle__tnd\\+P {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/SideDrawer/DrawerToggle/DrawerToggle.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".DrawerToggle {\n  width: 45px;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  justify-content: space-around;\n  align-items: center;\n  padding: 10px 0;\n  box-sizing: border-box;\n  cursor: pointer;\n}\n\n.DrawerToggle div {\n  width: 90%;\n  height: 3px;\n  background-color: var(--foregroundColor);\n}\n\n@media (min-width: 500px) {\n  .DrawerToggle {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DrawerToggle": `DrawerToggle_DrawerToggle__tnd+P`
};
export default ___CSS_LOADER_EXPORT___;
